import '../public/global.css'
import '../public/cookiebot-custom.css'

import { useShopifyCookies } from '@shopify/hydrogen-react'
import cx from 'classnames'
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion'
import { type AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Router } from 'next/router'
import { type ReactNode, useEffect, useContext, useRef } from 'react'

import { type SanityPage } from '@data/sanity/queries/types/page'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { AnalyticsEventName } from '@lib/analytics'
import {
  AnalyticsContext,
  AnalyticsContextProvider,
} from '@lib/analytics-context'
import { pageTransitionSpeed } from '@lib/animate'
import { CartContext } from '@lib/cart/context'
import { helvetica } from '@lib/fonts'
import { type Locale } from '@lib/language'
import { LanguageContextProvider } from '@lib/language-context'
import { MetadataContextProvider } from '@lib/metadata-context'
import { type BasePageProps } from '@lib/page'
import { type ProductCombinedListing } from '@lib/product/combined-listing/types'
import { ProductContextProvider } from '@lib/product-context'
import { ProductCombinedListingContextProvider } from '@lib/product/combined-listing/context'
import { ProductSearchContextProvider } from '@lib/product-search-context'
import { ShopContextProvider } from '@lib/shop-context'
import { SiteContext, SiteContextProvider } from '@lib/site-context'
import { StringsContextProvider } from '@lib/strings-context'

import ExternalScripts from '@components/external-scripts'
import ProgressBar from '@components/progress-bar'
import CartModal from '@modules/shop/cart/modal'

const PreviewProvider = dynamic(() => import('@lib/sanity/preview'))

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

interface NextHistoryState {
  url: string
  as: string
  options: TransitionOptions
}

type AppPageProps = BasePageProps & {
  site: SanitySiteFragment | null
  page: SanityPage | null
  product?: SanityProductFragment
  combinedListing?: ProductCombinedListing
}

type DefaultAppProps = AppProps<AppPageProps>

type CustomAppProps = DefaultAppProps & {
  pageProps: AppPageProps
}

type SiteProps = Pick<DefaultAppProps, 'router'> & {
  pageProps: AppPageProps
  children: ReactNode
  className?: string
}

/**
 * Add new position to scroll positions.
 */
const addScrollPosition = (
  positions: Record<string, number>,
  locale: Locale,
  url: string,
  position: number,
) => {
  const key = `${locale}:${url}`
  const alternativeKey = `${locale}:/${locale}${url.replace(/\/+$/g, '')}`

  return {
    ...positions,
    [key]: position,
    [alternativeKey]: position,
  }
}

/**
 * Router event handler hook.
 */
const useRouterEvents = (router: Router, locale: Locale) => {
  const { toggleCart } = useContext(CartContext)
  const { toggleIsRouteChanging, toggleMobileMenu, toggleMegaNavigation } =
    useContext(SiteContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const scrollPositions = useRef<Record<string, number>>({})
  const shouldScrollRestore = useRef(false)
  const isInitialLoad = useRef(true)

  useEffect(() => {
    // Prevent browser scroll restoration
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY,
        )
      }

      delete event['returnValue']
    }

    function handleRouteChangeStart(_: string, { shallow }: TransitionOptions) {
      toggleMobileMenu(false)
      toggleCart(false)

      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY,
        )
      }

      // Check if URL is changing
      if (!shallow) {
        toggleIsRouteChanging(true)
      }
    }

    function handleRouteChangeComplete(
      url: string,
      options: TransitionOptions,
    ) {
      // Wait for page transition to complete
      setTimeout(() => toggleIsRouteChanging(false), pageTransitionSpeed)

      // Check if URL is changing
      if (!isInitialLoad.current && !options.shallow) {
        // Restore scroll position after route change completes
        const position = scrollPositions.current[`${locale}:${url}`]
        const top = position && shouldScrollRestore.current ? position : 0

        // Restore scroll position or set it to 0
        setTimeout(
          () => requestAnimationFrame(() => window.scrollTo({ top })),
          pageTransitionSpeed + 100,
        )

        shouldScrollRestore.current = false
      }

      if (!options.shallow) {
        // Wait for document title to update, then trigger event
        setTimeout(() => {
          triggerAnalyticsEvent(AnalyticsEventName.Pageview)
        }, pageTransitionSpeed + 101)
      }

      toggleMegaNavigation(false)

      isInitialLoad.current = false
    }

    function handleRouteChangeError() {
      toggleIsRouteChanging(false)
    }

    function handleBeforePopState({ options }: NextHistoryState) {
      // Allow scroll position restoring
      shouldScrollRestore.current = true
      options.scroll = false

      return true
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)
    router.beforePopState(handleBeforePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
      router.beforePopState(() => true)
    }
  }, [
    locale,
    router,
    toggleCart,
    toggleMegaNavigation,
    toggleMobileMenu,
    toggleIsRouteChanging,
    triggerAnalyticsEvent,
  ])
}

const Site = ({ pageProps, router, children, className }: SiteProps) => {
  const { isRouteChanging } = useContext(SiteContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  useShopifyCookies({
    hasUserConsent: true,
    // TODO: Move to env variable.
    domain: 'newamsterdamsurf.com',
  })

  // Handle router events & scroll position restoration
  useRouterEvents(router, pageProps.locale)

  // Handle keyboard navigation
  useEffect(() => {
    function handleKeyDown({ key }: KeyboardEvent) {
      // Check if "tab" key was pressed
      if (key === 'Tab' && typeof window !== 'undefined') {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  // Trigger pageview event on page load
  useEffect(() => {
    triggerAnalyticsEvent(AnalyticsEventName.Pageview)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={className}>
      {isRouteChanging && pageProps.site?.strings?.loadingPageTitle && (
        <Head>
          <title>{pageProps.site.strings.loadingPageTitle}</title>
        </Head>
      )}

      <ProgressBar isAnimating={isRouteChanging} />

      <LazyMotion features={domAnimation}>
        <AnimatePresence
          mode="wait"
          onExitComplete={() =>
            document.body.classList.remove('overflow-hidden')
          }
        >
          {children}
        </AnimatePresence>
      </LazyMotion>

      <CartModal cartSettings={pageProps.site?.cartSettings} />

      <ExternalScripts settings={pageProps.site?.generalSettings} />
    </div>
  )
}

const CustomApp = ({ Component, pageProps, router }: CustomAppProps) => {
  if (!pageProps.site) {
    return <Component />
  }

  let app = (
    <StringsContextProvider site={pageProps.site}>
      <SiteContextProvider site={pageProps.site}>
        <MetadataContextProvider>
          <ShopContextProvider locale={pageProps.locale} site={pageProps.site}>
            <LanguageContextProvider
              locale={pageProps.locale}
              site={pageProps.site}
            >
              <ProductSearchContextProvider>
                <ProductContextProvider product={pageProps.product}>
                  <ProductCombinedListingContextProvider
                    combinedListing={pageProps.combinedListing}
                  >
                    <AnalyticsContextProvider>
                      <Site
                        pageProps={pageProps}
                        router={router}
                        className={cx(
                          'font-sans',
                          helvetica.variable,
                          // Include page path in class names for web vitals reporting
                          `path:${router.asPath}`,
                        )}
                      >
                        <Component
                          key={router.asPath.split('?')[0]}
                          {...pageProps}
                        />
                      </Site>
                    </AnalyticsContextProvider>
                  </ProductCombinedListingContextProvider>
                </ProductContextProvider>
              </ProductSearchContextProvider>
            </LanguageContextProvider>
          </ShopContextProvider>
        </MetadataContextProvider>
      </SiteContextProvider>
    </StringsContextProvider>
  )

  if (pageProps.draftMode) {
    app = (
      <PreviewProvider
        token={pageProps.draftModeToken}
        studioUrl={pageProps.draftModeStudioUrl}
      >
        {app}
      </PreviewProvider>
    )
  }

  return app
}

export default CustomApp

import axios from 'axios'
import { useCallback, useContext, useMemo } from 'react'
import useSWR from 'swr'

import {
  type SanityProductOption,
  type SanityProductOptionName,
  type SanityProductVariantFragment,
  type SanityProductFragment,
  type SanityProductOptionSetting,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { hasObject, parseOptionalParameter } from './helpers'
import { type TransformUrl, usePrevious, useUrlParameters } from './hooks'
import { type Locale } from './language'
import { SiteContext } from './site-context'

export interface Filter {
  name: string
  values: string[]
  productIds?: number[]
}

export interface FilterValue {
  name: string
  value: string
}

interface ProductInventoryRequest {
  localeHeader: Locale
  url: string
  id?: number
}

interface ProductInventoryVariant {
  id: number
  inStock: boolean
  lowStock: boolean
}

export interface ProductInventory {
  inStock: boolean
  lowStock: boolean
  variants: ProductInventoryVariant[]
}

export const productLowStockAmount = 10

export const productVariantLowStockAmount = 5

/**
 * Converts Sanity product or variant ID string into Shopify ID number.
 */
export const sanityProductIdToShopifyId = (sanityProductId: string) => {
  const shopifyProductId = sanityProductId.split('-')?.[1]

  if (!shopifyProductId) {
    return null
  }

  return Number(shopifyProductId)
}

/**
 * Gets product option label.
 */
export const getOptionListLabel = (
  optionNames: SanityProductOptionName[],
  option: SanityProductOption,
) =>
  optionNames?.find(({ forOption }) => forOption === option.name)?.name ||
  option.name

/**
 * Gets default product option.
 */
export const getDefaultOption = (
  options: SanityProductOption[],
  optionSettings: SanityProductOptionSetting[],
): SanityProductVariantOption | undefined => {
  if (options.length === 0) {
    return
  }

  const firstOption = options?.[0]
  const defaultOption = {
    name: firstOption?.name,
    value: firstOption?.values?.[0],
    position: firstOption?.position,
  }

  if (optionSettings.length === 0) {
    return defaultOption
  }

  // Use first option setting to find default option
  const settingParts = optionSettings[0].forOption?.split(':')
  const name = settingParts?.[0]
  const value = settingParts?.[1]
  const position = options.find((option) => option.name === name)?.position

  if (
    typeof name === 'undefined' ||
    typeof value === 'undefined' ||
    typeof position === 'undefined'
  ) {
    return defaultOption
  }

  return { name, value, position }
}

/**
 * Gets a product variant by the default option.
 */
export const getVariantByDefaultOption = (
  variants: SanityProductVariantFragment[],
  defaultOption?: SanityProductVariantOption,
) => {
  if (!defaultOption) {
    return null
  }

  const variant = variants.find(({ options }) =>
    hasObject(options, defaultOption),
  )

  return variant ?? null
}

/**
 * Product with inventory data hook.
 */
export const useProductWithInventory = (
  locale: Locale,
  product?: SanityProductFragment,
): SanityProductFragment | undefined => {
  // Read product inventory
  const productInventoryRequest: ProductInventoryRequest = {
    localeHeader: locale,
    url: '/api/shopify/product-inventory',
    id: product?.productID,
  }
  const { data: productInventory } = useSWR<ProductInventory | undefined>(
    productInventoryRequest,
    async ({ localeHeader, url, id }: ProductInventoryRequest) => {
      if (!id) {
        return
      }

      const response = await axios.get<ProductInventory>(url, {
        params: {
          id,
        },
        headers: {
          'X-Locale': localeHeader,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    },
  )

  return useMemo(() => {
    if (!product || !productInventory) {
      return product
    }

    const productVariants = product.variants ?? []

    return {
      ...product,
      inStock: productInventory.inStock,
      lowStock: productInventory.lowStock,
      variants: productVariants.map((productVariant) => {
        const productInventoryVariant = productInventory.variants.find(
          ({ id }) => id === productVariant.variantID,
        )

        if (!productInventoryVariant) {
          return productVariant
        }

        return {
          ...productVariant,
          ...productInventoryVariant,
        }
      }),
    }
  }, [product, productInventory])
}

/**
 * Active product variant hook.
 */
export const useActiveVariant = (
  productVariants?: SanityProductVariantFragment[],
  productOptions?: SanityProductOption[],
  productOptionSettings?: SanityProductOptionSetting[],
  transformUrl?: TransformUrl,
) => {
  const { isRouteChanging } = useContext(SiteContext)

  const defaultVariantId = useMemo(() => {
    if (typeof productOptions === 'undefined') {
      return null
    }

    const defaultOption = getDefaultOption(
      productOptions,
      productOptionSettings ?? [],
    )
    const firstVariant = productVariants?.[0] ?? null
    const defaultVariant =
      getVariantByDefaultOption(productVariants ?? [], defaultOption) ??
      firstVariant
    return defaultVariant?.variantID ?? null
  }, [productOptions, productOptionSettings, productVariants])

  const [currentParameters, setCurrentParameters] = useUrlParameters(
    [
      {
        name: 'variant',
        value: defaultVariantId ? `${defaultVariantId}` : null,
      },
    ],
    {
      transformUrl,
    },
  )

  // Manage URL parameters
  const previousParameters = usePrevious(currentParameters)
  const activeParameters = useMemo(() => {
    return isRouteChanging && previousParameters
      ? previousParameters
      : currentParameters
  }, [currentParameters, previousParameters, isRouteChanging])

  // Find active variant
  const variantIds = useMemo(() => {
    return productVariants?.map((variant) => variant.variantID) ?? []
  }, [productVariants])

  const activeVariantId = useMemo(() => {
    const parameterVariant = activeParameters.find(
      (activeParameter) => activeParameter.name === 'variant',
    )
    const parameterVariantValue = parseOptionalParameter<string>(
      parameterVariant?.value,
    )
    const parameterVariantId = parameterVariantValue
      ? Number(parameterVariantValue)
      : null

    return variantIds.some((id) => id == parameterVariantId)
      ? parameterVariantId
      : defaultVariantId
  }, [activeParameters, defaultVariantId, variantIds])

  const activeVariant = useMemo(() => {
    return productVariants?.find(
      (variant) => variant.variantID === activeVariantId,
    )
  }, [activeVariantId, productVariants])

  // Handle variant change
  const setActiveVariantId = useCallback(
    (variantId: number) => {
      const isValidVariant = variantIds.some((id) => id === variantId)

      setCurrentParameters([
        ...activeParameters.filter(
          (activeParameter) => activeParameter.name !== 'variant',
        ),
        {
          name: 'variant',
          value: isValidVariant ? `${variantId}` : `${defaultVariantId}`,
        },
      ])
    },
    [activeParameters, defaultVariantId, setCurrentParameters, variantIds],
  )

  return [activeVariant, setActiveVariantId] as const
}

/**
 * Gets variant using active options, option name and value.
 */
export const getVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  variantOptions: SanityProductVariantOption[],
  optionName: string,
  optionValue: string,
) => {
  const newVariantOptions = variantOptions.map((variantOption) => {
    const newVariantOption: SanityProductVariantOption = {
      position: variantOption.position,
      name: variantOption.name,
      value: variantOption.value,
    }

    if (variantOption.name === optionName) {
      newVariantOption.value = optionValue
    }

    return newVariantOption
  })

  // Find variant that matches all new options
  return variants.find((variant) =>
    variant.options.every((variantOption) => {
      const currentVariantOption: SanityProductVariantOption = {
        position: variantOption.position,
        name: variantOption.name,
        value: variantOption.value,
      }

      return hasObject(newVariantOptions, currentVariantOption)
    }),
  )
}

/**
 * Gets fallback variant using option name and value.
 */
export const getFallbackVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  optionName: string,
  optionValue: string,
) =>
  variants.find((variant) =>
    variant.options.some(
      (variantOption) =>
        variantOption.name === optionName &&
        variantOption.value === optionValue,
    ),
  )

/**
 * Gets product key for lists of products in React.
 */
export const getProductKey = (product: SanityProductFragment) => {
  const variantKey =
    product.variants
      ?.map((variant) => variant.variantID)
      ?.filter(Boolean)
      ?.join('-') ?? 'defaultVariant'

  return `${product.productID}-${variantKey}`
}

import {
  type ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import {
  type CountryCode,
  type CurrencyCode,
} from '@data/shopify/storefront/types'
import { CartContextProvider } from './cart/context'
import { type Locale } from './language'
import { getShopifyCartIdStorageKey } from './local-storage'
import { getShopifyDomain } from './shopify/client'
import {
  type ShopifyClient,
  getShopifyStorefrontGraphQlClient,
} from './shopify/graphql/client'
import { type ShopifyShop, getShopifyShopAndCart } from './shopify/graphql/shop'

export interface LocalizedShop {
  locale: Locale
  shop: ShopifyShop | null
}

interface ShopContextProps {
  taxRate: number
  shopId?: string
  currency?: string
  currencyCode?: CurrencyCode
  countryCode?: CountryCode
  shopifyDomain?: string
  shopifyPrimaryDomain?: string
  shopifyStorefrontGraphQlClient?: ShopifyClient
}

interface ShopContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  children: ReactNode
}

export const ShopContext = createContext<ShopContextProps>({
  taxRate: 0,
})

export const ShopContextProvider = ({
  locale,
  site,
  children,
}: ShopContextProviderProps) => {
  const shopifyDomain = getShopifyDomain(locale)

  const [localeInitialised, setLocaleInitialised] = useState<Locale>()
  const [shopId, setShopId] = useState<string>()
  const [currency, setCurrency] = useState<string>()
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>()
  const [countryCode, setCountryCode] = useState<CountryCode>()
  const [shopifyPrimaryDomain, setShopifyPrimaryDomain] = useState<
    string | undefined
  >(shopifyDomain)
  const [localizedShop, setLocalizedShop] = useState<LocalizedShop>({
    locale,
    shop: null,
  })

  const shopifyStorefrontGraphQlClient = useMemo(() => {
    try {
      return getShopifyStorefrontGraphQlClient(locale)
    } catch {
      return
    }
  }, [locale])

  // Get Shopify shop and cart information (update when switching language)
  useEffect(() => {
    if (localeInitialised === locale) {
      return
    }

    setLocalizedShop({
      locale,
      shop: null,
    })

    const loadShop = async () => {
      if (!shopifyStorefrontGraphQlClient) {
        return
      }

      setLocaleInitialised(locale)

      const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
      const cartId = localStorage.getItem(shopifyCartIdStorageKey)
      const shopifyShop = await getShopifyShopAndCart(
        locale,
        shopifyStorefrontGraphQlClient,
        cartId,
      )
      setLocalizedShop({
        locale,
        shop: shopifyShop ?? null,
      })
    }

    loadShop()
  }, [localeInitialised, locale, shopifyStorefrontGraphQlClient])

  useEffect(() => {
    if (!localizedShop.shop) {
      return
    }

    setShopId(localizedShop.shop?.shop?.id)
    // Remove HTML tags and value placeholder "{{amount}}" from currency format string
    setCurrency(
      localizedShop.shop?.shop?.moneyFormat
        ?.replace(/<\/?[^>]+(>|$)/g, '')
        ?.replace(/\s*[{]+.*?[}]+\s*/g, ''),
    )
    setCurrencyCode(localizedShop.shop?.shop?.paymentSettings?.currencyCode)
    setCountryCode(localizedShop.shop?.shop?.paymentSettings?.countryCode)
    setShopifyPrimaryDomain(
      localizedShop.shop?.shop?.primaryDomain?.host ?? shopifyDomain,
    )
  }, [localizedShop.shop, shopifyDomain])

  return (
    <ShopContext.Provider
      value={{
        taxRate: site.cartSettings.taxRate ?? 0,
        shopId,
        currency,
        currencyCode,
        countryCode,
        shopifyDomain,
        shopifyPrimaryDomain,
        shopifyStorefrontGraphQlClient,
      }}
    >
      <CartContextProvider
        localizedShop={localizedShop}
        site={site}
        locale={locale}
      >
        {children}
      </CartContextProvider>
    </ShopContext.Provider>
  )
}

import {
  type AttributeInput,
  type CartAttributesUpdateMutation,
  type CartAttributesUpdateMutationVariables,
  type CartCreateMutation,
  type CartCreateMutationVariables,
  type CartFragmentFragment,
  type CartInput,
  type CartLineInput,
  type CartLineUpdateInput,
  type CartLinesAddMutation,
  type CartLinesAddMutationVariables,
  type CartLinesRemoveMutation,
  type CartLinesRemoveMutationVariables,
  type CartLinesUpdateMutation,
  type CartLinesUpdateMutationVariables,
  type CartNoteUpdateMutation,
  type CartNoteUpdateMutationVariables,
} from '@data/shopify/storefront/types'
import {
  CART_ATTRIBUTES_UPDATE,
  CART_CREATE,
  CART_LINES_ADD,
  CART_LINES_REMOVE,
  CART_LINES_UPDATE,
  CART_NOTE_UPDATE,
} from '@data/shopify/storefront/mutations/cart'
import { type Locale } from '@lib/language'
import { type ShopifyClient, mutateShopifyStorefront } from './client'

export interface CartResponse {
  cart?: CartFragmentFragment
  error?: string
}

export type CartFragmentFragmentLine =
  CartFragmentFragment['lines']['edges'][0]['node']

/**
 * Creates a new Shopify cart.
 */
export const createShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartInput: CartInput,
): Promise<CartResponse> => {
  try {
    const cartCreateResult = await mutateShopifyStorefront<
      CartCreateMutation,
      CartCreateMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CART_CREATE, {
      cartInput,
    })

    const cart = cartCreateResult.data?.cartCreate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartCreateResult.data?.cartCreate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Updates attributes on Shopify cart.
 */
export const updateShopifyCartAttrbites = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  attributes: AttributeInput[],
): Promise<CartResponse> => {
  try {
    const cartAttributesUpdateResult = await mutateShopifyStorefront<
      CartAttributesUpdateMutation,
      CartAttributesUpdateMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CART_ATTRIBUTES_UPDATE, {
      cartId,
      attributes,
    })

    const cart = cartAttributesUpdateResult.data?.cartAttributesUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartAttributesUpdateResult.data?.cartAttributesUpdate?.userErrors?.[0]
          ?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Updates note on Shopify cart.
 */
export const updateShopifyCartNote = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  note: string,
): Promise<CartResponse> => {
  try {
    const cartNoteUpdateResult = await mutateShopifyStorefront<
      CartNoteUpdateMutation,
      CartNoteUpdateMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CART_NOTE_UPDATE, {
      cartId,
      note,
    })

    const cart = cartNoteUpdateResult.data?.cartNoteUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartNoteUpdateResult.data?.cartNoteUpdate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Adds new cart line items.
 */
export const addLineItemsToShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lines: CartLineInput[],
): Promise<CartResponse> => {
  try {
    const cartLinesAddResult = await mutateShopifyStorefront<
      CartLinesAddMutation,
      CartLinesAddMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CART_LINES_ADD, {
      cartId,
      lines,
    })

    const cart = cartLinesAddResult.data?.cartLinesAdd?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesAddResult.data?.cartLinesAdd?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Updates cart line items.
 */
export const updateLineItemsInShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lines: CartLineUpdateInput[],
): Promise<CartResponse> => {
  try {
    const cartLinesUpdateResult = await mutateShopifyStorefront<
      CartLinesUpdateMutation,
      CartLinesUpdateMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CART_LINES_UPDATE, {
      cartId,
      lines,
    })

    const cart = cartLinesUpdateResult.data?.cartLinesUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesUpdateResult.data?.cartLinesUpdate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Removes cart line items.
 */
export const removeLineItemsFromShopifyCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  lineIds: string[],
): Promise<CartResponse> => {
  try {
    const cartLinesRemoveResult = await mutateShopifyStorefront<
      CartLinesRemoveMutation,
      CartLinesRemoveMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CART_LINES_REMOVE, {
      cartId,
      lineIds,
    })

    const cart = cartLinesRemoveResult.data?.cartLinesRemove?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesRemoveResult.data?.cartLinesRemove?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

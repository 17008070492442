import { type Locale } from './language'

type LocaleEnvironmentVariableKey =
  | 'NEXT_PUBLIC_SHOPIFY_STORE_ID'
  | 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_ID'
  | 'NEXT_PUBLIC_SHOPIFY_API_TOKEN'
  | 'SHOPIFY_API_PASSWORD'
  | 'SHOPIFY_WEBHOOK_INTEGRITY'
  | 'REPORT_URI_SUBDOMAIN'

type LocaleEnvironmentVariableValues = Record<
  LocaleEnvironmentVariableKey,
  string | undefined
>

type LocaleEnvironmentVariableMap = Record<
  Locale,
  LocaleEnvironmentVariableValues
>

const defaultLocaleEnvironmentVariables: LocaleEnvironmentVariableValues = {
  NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ID:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ID,
  NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN,
  SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD,
  SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY,
  REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN,
}

const localeEnvironmentVariables: LocaleEnvironmentVariableMap = {
  en: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_EN,
    NEXT_PUBLIC_SHOPIFY_STOREFRONT_ID:
      process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ID_EN,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_EN,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_EN,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_EN,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_EN,
  },
}

/**
 * Gets environment variable for specific locale.
 */
export const getLocaleVariable = (
  locale: Locale,
  key: LocaleEnvironmentVariableKey,
) => {
  const localeValue = localeEnvironmentVariables[locale]?.[key]

  if (typeof localeValue === 'undefined' || localeValue === '') {
    return defaultLocaleEnvironmentVariables[key]
  }

  return localeValue
}
